// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

#root{
  --card-color: "#ffffff";
  --link-background-color: #f5f6f6;
}

.MuiTypography-root{
  color: #333333 ;
}

.MuiTypography-subtitle1{
  color: #111b214d ;
}

@media (prefers-color-scheme: dark) {
  #root {
    --card-color: #202c33;
    --link-background-color: #1d282f;
  }
  
  .MuiTypography-root{
    color: #ffffff ;
  }

  .MuiTypography-subtitle1{
    color: #e9edef4d ;
  }
  
}


`, "",{"version":3,"sources":["webpack://./src/index.css"],"names":[],"mappings":"AAAA;EACE,SAAS;EACT;;cAEY;EACZ,mCAAmC;EACnC,kCAAkC;AACpC;;AAEA;EACE;aACW;AACb;;AAEA;EACE,uBAAuB;EACvB,gCAAgC;AAClC;;AAEA;EACE,eAAe;AACjB;;AAEA;EACE,iBAAiB;AACnB;;AAEA;EACE;IACE,qBAAqB;IACrB,gCAAgC;EAClC;;EAEA;IACE,eAAe;EACjB;;EAEA;IACE,iBAAiB;EACnB;;AAEF","sourcesContent":["body {\n  margin: 0;\n  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',\n    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',\n    sans-serif;\n  -webkit-font-smoothing: antialiased;\n  -moz-osx-font-smoothing: grayscale;\n}\n\ncode {\n  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',\n    monospace;\n}\n\n#root{\n  --card-color: \"#ffffff\";\n  --link-background-color: #f5f6f6;\n}\n\n.MuiTypography-root{\n  color: #333333 ;\n}\n\n.MuiTypography-subtitle1{\n  color: #111b214d ;\n}\n\n@media (prefers-color-scheme: dark) {\n  #root {\n    --card-color: #202c33;\n    --link-background-color: #1d282f;\n  }\n  \n  .MuiTypography-root{\n    color: #ffffff ;\n  }\n\n  .MuiTypography-subtitle1{\n    color: #e9edef4d ;\n  }\n  \n}\n\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
