import React, { useRef, useState } from "react";
import {
  Container,
  TextField,
  Button,
  Card,
  CardContent,
  Typography,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  Box,
  CardActions,
  Link,
} from "@mui/material";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";
import html2canvas from "html2canvas";

function App() {
  const captureRef = useRef(null);
  const [businessName, setBusinessName] = useState("");
  const [location, setLocation] = useState("Bangalore");
  const [showCard, setShowCard] = useState(false);

  // Image URLs for each location
  const officeImages = {
    Bangalore: "./image.webp", // Replace with actual image URL
    Hyderabad: "./image.webp", // Replace with actual image URL
  };

  const handlePreview = () => {
    setShowCard(true);
  };
  const defaultText = "Hi, \nCheckout our experience center here: \n";

  const shareOnWhatsapp = async () => {
    try {
      if (!navigator.share) {
        alert("Web Share API is not supported in this browser.");
        return;
      }
      const canvas = await html2canvas(captureRef.current, {
        scale: 2, // Increase resolution of the screenshot
      });

      // Convert to Blob and File
      canvas.toBlob(async (blob) => {
        const file = new File([blob], "image.png", {
          type: "image/png",
        });

        // Share using the Web Share API with the files property

        await navigator
          .share({
            title: "Share via WhatsApp",
            text: defaultText,
            url: businessUrl,
            files: [file],
          })
          .catch((error) => {
            console.error("Share Cancelled:", error);
          });
      });
    } catch (error) {
      console.error("Error sharing:", error);
    }
  };

  const handleReset = () => {
    setBusinessName("");
    setLocation("Bangalore");
    setShowCard(false);
  };

  const businessUrl =
    location === "Bangalore"
      ? "https://maps.app.goo.gl/VPXgkk1iicrs8UyGA"
      : "https://maps.app.goo.gl/HLibD8skxeMztTyG8";

  return (
    <Container>
      <h1>Business Card Generator</h1>
      <TextField
        label="Business Name"
        variant="outlined"
        fullWidth
        value={`${businessName}`}
        onChange={(e) => setBusinessName(e.target.value)}
      />
      <FormControl fullWidth margin="normal">
        <InputLabel id="officeLocationLabel">Office Location</InputLabel>
        <Select
          labelId="officeLocationLabel"
          id="officeLocation"
          label="Office Location"
          value={location}
          onChange={(e) => setLocation(e.target.value)}
        >
          <MenuItem value="Bangalore">Bangalore</MenuItem>
          <MenuItem value="Hyderabad">Hyderabad</MenuItem>
        </Select>
      </FormControl>
      <Button
        variant="contained"
        color="primary"
        onClick={handlePreview}
        disabled={!businessName}
      >
        Preview
      </Button>

      <Button
        variant="outlined"
        color="secondary"
        onClick={handleReset}
        style={{ marginLeft: "10px" }}
      >
        Reset
      </Button>

      {showCard && (
        <Card sx={{ width: "420px", maxWidth: "100%", my: 2 }}>
          <CardContent
            sx={{ padding: "10px", backgroundColor: "var(--card-color)" }}
          >
            <Box ref={captureRef}>
              <img
                src={officeImages[location]}
                alt={`${location} Office`}
                style={{ width: "100%" }}
              />
              <Box
                sx={{
                  padding: "12px 12px",
                  backgroundColor: "var(--link-background-color)",
                  marginTop: "-4px",
                }}
              >
                <Typography
                  variant="h3"
                  sx={{
                    fontSize: "20px",
                    fontWeight: "600",
                    fontFeatureSettings: "kern",
                    mb: "2px",
                    fontFamily:
                      '"SF Pro Text", "SF Pro Icons", system, -apple-system, system-ui, BlinkMacSystemFont, "Helvetica Neue", "Helvetica", "Arial", "Lucida Grande", "Kohinoor Devanagari", sans-serif',
                  }}
                >{`${businessName} - Powered By Finemake`}</Typography>
                <Typography
                  variant="subtitle1"
                  sx={{
                    verticalAlign: "baseline",
                    whiteSpace: "pre-line",
                    fontSize: "16px",
                    fontWeight: "600",
                    fontFeatureSettings: "kern",
                    fontFamily:
                      '"SF Pro Text", "SF Pro Icons", system, -apple-system, system-ui, BlinkMacSystemFont, "Helvetica Neue", "Helvetica", "Arial", "Lucida Grande", "Kohinoor Devanagari", sans-serif',
                  }}
                >
                  <Typography
                    variant="subtitle1"
                    sx={{
                      verticalAlign: "baseline",
                      whiteSpace: "pre-line",
                      fontSize: "16px",
                      fontWeight: "600",
                      fontFeatureSettings: "kern",
                      color: "gold",
                      fontFamily:
                        '"SF Pro Text", "SF Pro Icons", system, -apple-system, system-ui, BlinkMacSystemFont, "Helvetica Neue", "Helvetica", "Arial", "Lucida Grande", "Kohinoor Devanagari", sans-serif',
                    }}
                    component={"span"}
                  >
                    &#9733; &#9733; &#9733; &#9733; &#9733;
                  </Typography>
                  {` · 4.7 · Manufacturer `}
                </Typography>

                <Typography
                  variant="subtitle1"
                  sx={{
                    verticalAlign: "baseline",
                    whiteSpace: "pre-line",
                    fontSize: "16px",
                    fontFeatureSettings: "kern",
                    fontFamily:
                      '"SF Pro Text", "SF Pro Icons", system, -apple-system, system-ui, BlinkMacSystemFont, "Helvetica Neue", "Helvetica", "Arial", "Lucida Grande", "Kohinoor Devanagari", sans-serif',
                  }}
                >{`${
                  location === "Bangalore" ? "Bangalore" : "Hyderabad"
                }`}</Typography>
              </Box>
            </Box>
            <Box sx={{ mt: 1 }}>
              <Typography>Hi,</Typography>
              <Typography>
                Checkout our experience center here:{" "}
                <Link sx={{ display: "inline" }}>{businessUrl}</Link>
              </Typography>
            </Box>
          </CardContent>
          <CardActions>
            <Button
              fullWidth
              variant="outlined"
              color="success"
              onClick={shareOnWhatsapp}
              endIcon={<WhatsAppIcon htmlColor="#25d366" />}
            >
              Share on WhatsApp
            </Button>
          </CardActions>
        </Card>
      )}
    </Container>
  );
}

export default App;
